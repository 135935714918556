import { API, Helpers, Router, Translator } from '../Helpers';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ErrorBoundary from '../../withErrorBoundary/withErrorBoundary';
import {InputHidden, Pagination, Select, TextArea} from "../Core";
import TableColumns from "./TableColumns";

export default class Index extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };

        this.getLocationType = this.getLocationType.bind(this);
    }

    getLocationType(location_type_id)
    {
        return _.find(this.props.data.location_types.data, (_location_type) => { return _location_type.id == location_type_id; });
    }

    render() {

        const { active_site, admin_site, alert, csrf_token, env, languages, locale, locations, request, routes, user } = this.props.data;
        const router = Router(routes);

        return (
            <div>
                <div className="container-fluid pb-4">
                    {
                        !_.isEmpty(alert) &&
                        <div className="row mb-3">
                            <div className="col-12">
                                <div className={"alert mb-3 " + alert.type} role="alert">
                                    <div>
                                        <h4 className="font-weight-bold"><i className={"fa-lg mr-2 " + alert.icon}></i>{alert.heading}</h4>
                                        <div>{alert.message}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="row mb-3">
                        <div className="col-12">
                            <h2>Locations</h2>
                        </div>
                    </div>

                    <form>
                        <div className="row form-row mb-3">
                            <div className="col-9">
                                <InputHidden name="locale" defaultValue={locale} />
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fas fa-search"></i></span>
                                    </div>
                                    <input
                                        name="query_filter"
                                        type="text"
                                        className="form-control"
                                        placeholder="Search for a location ID, name, or something..."
                                        defaultValue={request.query_filter}
                                    />
                                </div>
                            </div>
                            <div className="col-1">
                                <button className="btn btn-primary btn-block">Search</button>
                            </div>
                        </div>
                    </form>

                    <div className="row">
                        <div className="col-12">
                            <div className="card border mb-3">
                                <div className="card-body p-0">
                                    <table className="table table-striped m-0">
                                        <thead>
                                            <TableColumns />
                                        </thead>
                                        <tbody>
                                            {
                                                locations.data.map((location, key) => {

                                                    let location_type = this.getLocationType(location.location_type_id);

                                                    return (
                                                        <tr key={key}>
                                                            <td className="align-middle">
                                                                <div className="mb-1">
                                                                    <span className="font-weight-bold">{location.name}</span>
                                                                </div>
                                                            </td>
                                                            <td className="align-middle">{location.location_id}</td>
                                                            <td className="align-middle">{_.capitalize(location_type.name)}</td>
                                                            <td className="align-middle">{location.code}</td>
                                                            <td className="align-middle">{location.country_name}</td>
                                                            <td className="align-middle">{location.city_name}</td>
                                                            <td className="align-middle">
                                                                <div className="dropdown">
                                                                    <button
                                                                        className="btn btn-flat dropdown-toggle float-right no-caret"
                                                                        type="button" data-toggle="dropdown"
                                                                        aria-haspopup="true"
                                                                        aria-expanded="false">
                                                                        <i className="fa fa-ellipsis-v"></i>
                                                                    </button>
                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                        <a className="dropdown-item" href={router.url('locations.edit', {locale: location.language_code, location: location.location_id, domain: admin_site.domain})}>Edit</a>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="d-flex justify-content-end">
                                <Pagination
                                    currentPage={this.props.data.locations.meta.pagination.current_page}
                                    pageSize={this.props.data.locations.meta.pagination.per_page}
                                    request={request}
                                    router={router}
                                    total={this.props.data.locations.meta.pagination.total}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

if (document.getElementById('locationsIndex')) {

    const element = document.getElementById('locationsIndex');
    const data = Object.assign({}, element.dataset);

    //console.log(data.data);

    ReactDOM.render(<ErrorBoundary><Index data={JSON.parse(data.data)} /></ErrorBoundary>, document.getElementById('locationsIndex'));
}
