import { API, Helpers, Router, Translator } from '../Helpers';
import BookingInformation from './BookingInformation';
import CancelBookingModal from './CancelBookingModal';
import CustomerInformation from './CustomerInformation';
import {
    InputText,
    Loader,
    TextArea,
    Switch } from '../Core';
import PriceSummary from './PriceSummary';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Redirect } from 'react-router-dom';
import ErrorBoundary from '../../withErrorBoundary/withErrorBoundary';
import TermsConditionsModal from './TermsConditionsModal';
import Transactions from './Transactions';
import VehicleDetails from './VehicleDetails';

let redirect = null;

export default class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            booking: {},
            booking_number: '',
            email_address: '',
            error: {},
            form_id: 'bookingSearchForm_' + uniqid(),
            redirect: false,
            show_loader: false
        };

        this.cancellable = this.cancellable.bind(this);
        this.changeBookingNumber = this.changeBookingNumber.bind(this);
        this.changeEmailAddress = this.changeEmailAddress.bind(this);
        this.fetchBooking = this.fetchBooking.bind(this);
        this.search = this.search.bind(this);
        this.totalProfit = this.totalProfit.bind(this);
    }

    componentDidMount()
    {
        $('#' + this.state.form_id).validate({
            //debug: true,
            errorClass: "is-invalid",
            validClass: "is-valid",
            errorPlacement: (error, element) => { /*just nothing, empty*/ }
        });
    }

    cancellable()
    {
        if (this.state.booking.paymentComponent !== "MerchantOne")
        {
            return false;
        }

        if (this.state.booking.status == "Cancelled")
        {
            return false;
        }

        return true
    }

    changeBookingNumber(event)
    {
        let booking_number = _.trim(event.target.value);

        this.setState({
            booking_number: booking_number
        });
    }

    changeEmailAddress(event)
    {
        let email_address = _.trim(event.target.value);

        this.setState({
            email_address: email_address
        });
    }

    fetchBooking(payload)
    {
        const router = Router(this.props.data.routes);

        let onResponse = {
            success: (response) =>
            {
                //console.log('fetchBooking Response', response);

                this.setState({
                    show_loader: false,
                    booking: response
                });
            },
            error: (error) =>
            {
                console.log(error);

                this.setState({
                    show_loader: false,
                    error: error,
                    booking: {}
                });
            }
        }

        this.setState({
            show_loader: true,
            booking: {},
            error: {}
        },() =>
        {
            let url = router.url('bookings.show', _.merge(payload, {domain: this.props.data.admin_site.domain}));

            API.get(onResponse, url);
        });
    }

    search(event)
    {
        event.preventDefault();

        if ($('#' + this.state.form_id).valid())
        {

            let payload = Helpers.serializeForm(event.target);

            this.fetchBooking(payload);
        }
    }

    totalProfit()
    {
        return this.state.booking.vehicle.package.vehicle_profit + this.state.booking.vehicle.package.extras_profit;
    }

    render() {

        const { active_site, admin_site, cancellation_reasons, csrf_token, env, language_term_translations, request, routes, user } = this.props.data;
        const router = Router(routes);
        const translator = Translator(language_term_translations);

        return (
            <div>
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-12">
                            <h2 className="mb-0">Find a booking</h2>
                        </div>
                    </div>

                    <form id={this.state.form_id} onSubmit={this.search}>
                        <div className="row form-row d-flex align-items-center">
                            <div className="col-3">
                                <InputText
                                    name="booking"
                                    value={this.state.booking_number}
                                    className="form-control form-control-lg rounded-0"
                                    placeholder="Booking number"
                                    onChange={this.changeBookingNumber}
                                    required
                                />
                            </div>
                            <div className="col-4">
                                <InputText
                                    name="email"
                                    value={this.state.email_address}
                                    className="form-control form-control-lg rounded-0"
                                    placeholder="Email address"
                                    onChange={this.changeEmailAddress}
                                    required
                                    email="true"
                                />
                            </div>
                            <div className="col-auto">
                                <button type="submit" className="btn btn-lg btn-primary px-4"><i className="fa fa-search mr-3"></i>Search</button>
                            </div>
                        </div>
                    </form>

                    <hr className="my-4" />


                    {
                        !_.isEmpty(this.state.error) &&
                        <div className="form-group row">
                            <div className="col-12">
                                <div className="alert alert-danger p-4 rounded-0" role="alert">
                                    <h4 className="font-weight-bold"><i className="fa fa-exclamation-circle mr-2"></i>Sorry, we could not find that booking.</h4>
                                    <div>Please check to make sure you entered the right booking number and email address.</div>
                                </div>
                            </div>
                        </div>
                    }

                    {
                        this.state.show_loader &&
                        <Loader />
                    }

                    {
                        !_.isEmpty(this.state.booking) &&
                        <React.Fragment>
                            <div className="row mb-4">
                                <div className="col-12">
                                    <ul className="list-inline d-flex align-items-center mb-0 font-large">
                                        <li className="list-inline-item">
                                            <div className="font-weight-bold">
                                                <a
                                                    target="_blank"
                                                    className="btn-link text-primary font-weight-bold"
                                                    href={router.url('office.bookings.show', {reserv_num: this.state.booking.reservNum})}>
                                                    #{this.state.booking.reservNum}-{this.state.booking.version}
                                                </a>
                                            </div>
                                        </li>
                                        <li className="list-inline-item">
                                            <span className="badge badge-secondary p-2 z-depth-0">{this.state.booking.status}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-12">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <ul className="nav btn-group mb-3 d-inline" role="tablist">
                                                <li className="nav-item btn btn-outline-secondary p-0" role="presentation">
                                                    <button
                                                        className="active btn btn-outline-secondary rounded-0 border-0"
                                                        data-toggle="tab"
                                                        href="#booking"
                                                        role="tab">
                                                        <i className="fa fa-car mr-2"></i>Booking
                                                    </button>
                                                </li>
                                                <li className="nav-item btn btn-outline-secondary p-0" role="presentation">
                                                    <button
                                                        className="btn btn-outline-secondary rounded-0 border-0"
                                                        data-toggle="tab"
                                                        href="#transaction"
                                                        role="tab"
                                                    >
                                                        <i className="far fa-credit-card mr-2"></i>Transactions
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div>
                                            <ul className="list-inline mb-0">
                                                <li className="list-inline-item">
                                                    <a href={router.url('customer.vouchers.show', {domain: active_site.site.domain, booking_number: this.state.booking.reservNum, access_token: this.state.booking.accessToken})} target="_blank" className="btn btn-outline-secondary"><i className="fas fa-receipt mr-2"></i>View voucher</a>
                                                </li>

                                                <li className="list-inline-item">
                                                    <div className="dropdown">
                                                        <button className="btn btn-secondary dropdown-toggle no-caret" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i className="fa fa-cog mr-2"></i><i className="fa fa-angle-down"></i>
                                                        </button>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                            <a
                                                                href={router.url('office.bookings.show', {reserv_num: this.state.booking.reservNum})}
                                                                className="dropdown-item"
                                                                target="_blank">
                                                                View in office
                                                            </a>

                                                            {
                                                                this.cancellable() &&
                                                                <button
                                                                    className="dropdown-item"
                                                                    data-toggle="modal"
                                                                    data-target="#cancelBookingModal">
                                                                    Cancel booking
                                                                </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="tab-content px-0 py-0">
                                        <div className="tab-pane fade show active" id="booking" role="tabpanel">
                                            <div className="row">
                                                <div className="col-12 col-lg-6">
                                                    <BookingInformation
                                                        booking={this.state.booking}
                                                        router={router}
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-6">
                                                    <CustomerInformation
                                                        booking={this.state.booking}
                                                        router={router}
                                                    />
                                                </div>
                                            </div>

                                            <hr className="my-4" />

                                            <div className="row mb-3">
                                                <div className="col-12">
                                                    <VehicleDetails
                                                        booking={this.state.booking}
                                                        env={env}
                                                        router={router}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12">
                                                    <PriceSummary
                                                        booking={this.state.booking}
                                                        env={env}
                                                        router={router}
                                                        totalProfit={this.totalProfit}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="transaction" role="tabpanel">
                                            <Transactions
                                                admin_site={admin_site}
                                                booking={this.state.booking}
                                                env={env}
                                                router={router}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                </div>

                {
                    !_.isEmpty(this.state.booking) &&
                    <CancelBookingModal
                        admin_site={admin_site}
                        booking={this.state.booking}
                        cancellation_reasons={cancellation_reasons}
                        csrf_token={csrf_token}
                        env={env}
                        fetchBooking={this.fetchBooking}
                        router={router}
                        totalProfit={this.totalProfit}
                        user={user}
                    />
                }

                {
                    !_.isEmpty(this.state.booking) &&
                    <TermsConditionsModal
                        active_site={active_site}
                        terms_conditions={this.state.booking.terms}
                        translator={translator}
                    />
                }
            </div>
        );
    }
}

if (document.getElementById('bookingsSearch')) {

    const element = document.getElementById('bookingsSearch');
    const data = Object.assign({}, element.dataset);

    //console.log(data.data);

    ReactDOM.render(<ErrorBoundary><App data={JSON.parse(data.data)} /></ErrorBoundary>, document.getElementById('bookingsSearch'));
}
