import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class InputHidden extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return (
            <input
                {...this.props}
                name={this.props.name}
                defaultValue={this.props.defaultValue}
                type="hidden"
                readOnly={this.props.readOnly}
            />
        );
    }
}
