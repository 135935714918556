import { API, Helpers, Router, Translator } from '../Helpers';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ErrorBoundary from '../../withErrorBoundary/withErrorBoundary';
import { InputHidden, Loader, Pagination, Select, TextArea } from "../Core";

export default class Edit extends Component {
    constructor(props) {
        super(props);

        let language_codes = this.props.data.languages

        if (!Array.isArray(this.props.data.languages)) {
            language_codes = Object.values(this.props.data.languages);
        }

        this.state = {
            show_loader: false,
            errors: {},
            translations: [...this.props.data.language_terms_translations.data],
            loadingTextAreas: [], // Track loading states for each TextArea
            successes: {},
            language_codes: language_codes,
            language_code: this.props.data.language_code
        };

        this.fetchSearchTranslationsKey = this.fetchSearchTranslationsKey.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);

    }

    handleChange = (index, newText) => {
        const updatedTranslations = [...this.state.translations];
        updatedTranslations[index].text = newText;

        this.setState({ translations: updatedTranslations });
    };
    handleBlur = async (index) => {

        const router = Router(this.props.data.routes);
        const translation = this.state.translations[index];

        const payload = {
            ...translation,
            id: translation.id,
            language_term_id: translation.language_term_id,
            locale: translation.locale,
            text: translation.text,
            _token: this.props.data.csrf_token
        };

        let onResponse = {
            success: (response) => {

                this.setState((prevState) => ({
                    loadingTextAreas: prevState.loadingTextAreas.filter((i) => i !== index),
                    show_loader: false,
                    errors: {
                        ...prevState.errors,
                        [translation.id]: null, // Clear the error for this translation
                    },
                    successes: {
                        ...prevState.successes,
                        [translation.id]: response.data.message || 'success'
                    }
                }));

            },
            error: (error) => {

                const errorMessage = error?.error || "An error occurred";
                this.setState((prevState) => ({
                    loadingTextAreas: prevState.loadingTextAreas.filter((i) => i !== index),
                    show_loader: false,
                    errors: {
                        ...prevState.errors,
                        [translation.id]: errorMessage, // Set the error for this translation
                    },
                    successes: {
                        ...prevState.successes,
                        [translation.id]: null
                    }
                }));

            }
        }

        this.setState((prevState) => ({
            loadingTextAreas: [...prevState.loadingTextAreas, index],
            show_loader: true,
            errors: {
                ...prevState.errors,
                [translation.id]: null, // Clear any existing error for this translation
            },
            successes: {
                ...prevState.successes,
                [translation.id]: null
            }
        }), () => {
            let url = router.url('language_translations.update', { 'language_code': this.props.data.language_code, domain: this.props.data.admin_site.domain });

            API.put(onResponse, payload, url);
        });

    };

    fetchSearchTranslationsKey(payload = {}) {
        this.setState({
            show_loader: true
        });
    }

    render() {

        const { active_site, admin_site, alert, csrf_token, env, languages, request, routes, language_terms_translations } = this.props.data;

        const { translations, loadingTextAreas, errors, successes, language_codes, language_code } = this.state;

        const router = Router(routes);

        // console.log('languages', language_codes)

        return (
            <div>
                <div className="container-fluid pb-4">

                    {
                        !_.isEmpty(translations) &&
                        <React.Fragment>
                            <div className="row mb-5 align-items-center">
                                <div className="col-10">
                                    <h2 className="mb-0">Language Translations</h2>
                                </div>
                                <div className="col-2 justify-content-end">
                                    <select
                                        name="language_code"
                                        className="custom-select form-control"
                                        value={language_code}
                                        onChange={(e) => {
                                            const selectedCode = e.target.value;
                                            if (selectedCode) {
                                                const actionUrl = router.url('language_translations.edit', { language_code: selectedCode, domain: admin_site.domain });
                                                window.location.href = actionUrl;
                                            }
                                        }}
                                    >
                                        <option value="">Select a language</option>
                                        {language_codes.map((language) => (
                                            <option key={language.id} value={language.code}>
                                                {language.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-12 col-lg-7">
                                    <form
                                        action={router.url('language_translations.edit', { 'language_code': language_code, domain: admin_site.domain })}
                                        method="GET"
                                    // onSubmit={this.fetchSearchTranslationsKey}
                                    >
                                        <div className="row form-row d-flex align-items-center">
                                            <div className="col-12 col-lg-6">
                                                <input name="query_filter" className="form-control form-control-lg rounded-0" placeholder="Enter a key to search" required />
                                            </div>
                                            <div className="col-12 col-lg-auto">
                                                <button type="submit" className="btn btn-block btn-lg btn-primary px-4"><i className="fa fa-search mr-3"></i>Search</button>
                                            </div>
                                            <div className="col-12 col-lg-auto">
                                                <a href={router.url('language_translations.edit', { 'language_code': language_code, domain: admin_site.domain })} className="btn btn-block btn-lg btn-secondary"><i className="fa fa-undo mr-3"></i>Reset</a>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-12 col-lg-5 d-lg-flex justify-content-lg-end align-items-center">
                                    <div>
                                        <Pagination
                                            currentPage={language_terms_translations.meta.pagination.current_page}
                                            pageSize={language_terms_translations.meta.pagination.per_page}
                                            request={request}
                                            router={router}
                                            total={language_terms_translations.meta.pagination.total}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                {
                                    translations.map((translations, index) => {

                                        return (
                                            <div key={index} className="col-12">
                                                <div className="card border mb-2 text-body">
                                                    <div className="card-body">
                                                        <label htmlFor={`translation-${translations.id}`}>
                                                            <h5 className="font-weight-bold mb-0">{translations.key}</h5>
                                                            <h6 className="font-weight-normal mb-2"><i>{translations.group}</i></h6>
                                                        </label>
                                                        <div className="row">
                                                            <div className="col-12 col-lg-6">
                                                                <TextArea
                                                                    id={`translation-${translations.id}`}
                                                                    value={translations.text || ''}
                                                                    rows={6}
                                                                    onChange={(e) => this.handleChange(index, e.target.value)}
                                                                    onBlur={() => this.handleBlur(index)}
                                                                    placeholder="Edit translation"
                                                                    disabled={loadingTextAreas.includes(index)}
                                                                />
                                                            </div>
                                                            <div className="col-12 col-lg-6">
                                                                <TextArea
                                                                    id={'base_' + translations.id}
                                                                    defaultValue={translations.english_text || ''}
                                                                    rows={6}
                                                                    readOnly={true}
                                                                />
                                                            </div>
                                                        </div>
                                                        {/* Display error message if exists */}
                                                        {errors[translations.id] && (
                                                            <div className="row">
                                                                <div className="col-12 col-lg-6">
                                                                    <div className="text-danger mt-1">
                                                                        {errors[translations.id]}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {successes[translations.id] && (
                                                            <div className="row">
                                                                <div className="col-12 col-lg-6">
                                                                    <div className="text-success mt-1">
                                                                        {successes[translations.id]}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="form-group row">
                                <div className="col-12 d-flex justify-content-end align-items-center">
                                    <div>
                                        <Pagination
                                            currentPage={language_terms_translations.meta.pagination.current_page}
                                            pageSize={language_terms_translations.meta.pagination.per_page}
                                            request={request}
                                            router={router}
                                            total={language_terms_translations.meta.pagination.total}
                                        />
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                </div>
            </div>
        );
    }

}

if (document.getElementById('languageTranslationsEdit')) {

    const element = document.getElementById('languageTranslationsEdit');
    const data = Object.assign({}, element.dataset);

    ReactDOM.render(<ErrorBoundary><Edit data={JSON.parse(data.data)} /></ErrorBoundary>, document.getElementById('languageTranslationsEdit'));
}
