import { Router } from '../Helpers';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ErrorBoundary from '../../withErrorBoundary/withErrorBoundary';
import { InputHidden, Loader } from "../Core";

class TopbarSites extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            search_string: null,
            form_id: "filtersForm_" + uniqid()
        };

        this.searchSites = this.searchSites.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.searchInput = null;

    }

    componentDidMount() {
        this.searchInput.focus();
    }

    searchSites(query) {
        this.setState({ search_string: query.target.value });
    }

    handleSubmit(site) {

        const form = document.getElementById(this.state.form_id);
        const hiddenInput = document.getElementById('site');
        hiddenInput.value = site.id;

        // Submit the form
        form.submit();
    }

    render() {

        const { sites, routes, domain, csrf_token, url, active_site } = this.props.data;

        let sites_list = sites.data;
        const router = Router(routes);

        if (!_.isEmpty(this.state.search_string)) {
            sites_list = sites.data.filter(x => x.site.name.toLowerCase().includes(this.state.search_string.toLowerCase()) || x.site.domain.toLowerCase().includes(this.state.search_string.toLowerCase()));
        }

        return (
            <div>
                <li className="nav-item mr-3 dropdown">
                    <a href="#" className="btn btn-lg btn-outline-dark dropdown-toggle no-caret border-0" data-toggle="dropdown">
                        <i className="fa fa-lg fa-search"></i>
                    </a>
                    <div className="dropdown-menu dropdown-primary dropdown-menu-right dropdown-menu-lg dropdown-scrollable p-0 overflow-y-scroll z-depth-0" style={{ maxHeight: 450 }}>
                        <div className="card">
                            <div className="card-header p-4">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className={"input-group-text rounded-0 pr-0 white"}><i className="fa fa-search"></i></span>
                                    </div>
                                    <input
                                        tabIndex="0"
                                        data-cy="sites_search_field"
                                        type="text"
                                        className="form-control rounded-0 border-left-0"
                                        placeholder='Site search...'
                                        onChange={(event) => this.searchSites(event)}
                                        ref={elem => (this.searchInput = elem)}
                                    />
                                </div>
                            </div>
                            <div className="card-body p-0">


                                <div className="list-group">
                                    <form
                                        id={this.state.form_id}
                                        method='POST'
                                        action={router.url('set_site', { domain: domain })}>
                                        <InputHidden name="_token" defaultValue={csrf_token} />
                                        <InputHidden id="site" name="site" defaultValue={''} />
                                        <InputHidden name="redirect_to" defaultValue={url} />
                                        {
                                            sites_list.map(site => {
                                                return (

                                                    <button
                                                        key={site.site.id}
                                                        type="button"
                                                        className={`list-group-item list-group-item-action ${active_site.site.id === site.site.id ? 'active' : ''}`}
                                                        onClick={() => this.handleSubmit(site.site)}>
                                                        <h4 className="mb-0 font-weight-bold">{site.site.name}</h4>
                                                        <small>{site.site.domain}</small>
                                                    </button>

                                                )
                                            })
                                        }
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </li>
            </div>
        )
    }
}

export default TopbarSites;

if (document.getElementById("topbarSites")) {
    const element = document.getElementById("topbarSites");
    const data = Object.assign({}, element.dataset);

    ReactDOM.render(<ErrorBoundary><TopbarSites data={JSON.parse(data.data)} /></ErrorBoundary>, document.getElementById("topbarSites"));
}