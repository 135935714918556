import { API, Helpers, Router, Translator } from '../Helpers';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ErrorBoundary from '../../withErrorBoundary/withErrorBoundary';
import { InputHidden, Pagination, Select, TextArea } from "../Core";
import TableColumns from "./TableColumns";

export default class Index extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {

        const { active_site, admin_site, alert, csrf_token, env, languages, users, request, routes, user } = this.props.data;
        const router = Router(routes);

        // console.log('users',users);

        // return (<React.Fragment>HI</React.Fragment>)

        return (
            <div>
                <div className="container-fluid pb-4">
                    {
                        !_.isEmpty(alert) &&
                        <div className="row mb-3">
                            <div className="col-12">
                                <div className={"alert mb-3 " + alert.type} role="alert">
                                    <div>
                                        <h4 className="font-weight-bold"><i className={"fa-lg mr-2 " + alert.icon}></i>{alert.heading}</h4>
                                        <div>{alert.message}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="row mb-3">
                        <div className="col-12">
                            <h2>Users <a href={router.url('users.create', {domain: admin_site.domain})} className="btn btn-primary">Add New</a></h2>
                        </div>
                    </div>

                    <form>
                        <div className="row form-row mb-3">
                            <div className="col-6">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fas fa-search"></i></span>
                                    </div>
                                    <input
                                        name="query_filter"
                                        type="text"
                                        className="form-control"
                                        placeholder="Search for a user by name"
                                        defaultValue={request.query_filter}
                                    />
                                </div>
                            </div>
                            <div className="col-1">
                                <button className="btn btn-primary btn-block">Search</button>
                            </div>
                        </div>
                    </form>

                    <div className="row">
                        <div className="col-12">
                            <div className="card border mb-3">
                                <div className="card-body p-0">
                                    <table className="table table-striped m-0">
                                        <thead>
                                            <TableColumns />
                                        </thead>
                                        <tbody>
                                            {
                                                users.data.map((user, key) => {

                                                    return (
                                                        <tr key={user.user.id}>
                                                            <td className="align-middle">
                                                                <div className="mb-1">
                                                                    <span className="font-weight-bold">{user.user.name}</span>
                                                                </div>
                                                            </td>
                                                            <td className="align-middle">{user.user.email}</td>
                                                            <td className="align-middle">
                                                                {
                                                                    user?.roles?.map((role, index) => {
                                                                        return (
                                                                            <div key={index} className="badge badge-dark z-depth-0">{role.role.name}</div>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                            <td className="align-middle">
                                                                {
                                                                    user.user.active?
                                                                    <i className="fa fa-check green-text"></i>
                                                                    :
                                                                     <i className="fa fa-times red-text"></i>
                                                                }
                                                            </td>
                                                            <td className="align-middle">{user.user.last_logged_in_at}</td>
                                                            <td className="align-middle">
                                                                <div className="dropdown">
                                                                    <button
                                                                        className="btn btn-flat dropdown-toggle float-right no-caret"
                                                                        type="button" data-toggle="dropdown"
                                                                        aria-haspopup="true"
                                                                        aria-expanded="false">
                                                                        <i className="fa fa-ellipsis-v"></i>
                                                                    </button>
                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                        <a className="dropdown-item" href={router.url('users.edit', { user: user.user.id, domain: admin_site.domain })}>Edit</a>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="d-flex justify-content-end">
                                <Pagination
                                    currentPage={this.props.data.users.meta.pagination.current_page}
                                    pageSize={this.props.data.users.meta.pagination.per_page}
                                    request={request}
                                    router={router}
                                    total={this.props.data.users.meta.pagination.total}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

if (document.getElementById('usersIndex')) {

    const element = document.getElementById('usersIndex');
    const data = Object.assign({}, element.dataset);

    //console.log(data.data);

    ReactDOM.render(<ErrorBoundary><Index data={JSON.parse(data.data)} /></ErrorBoundary>, document.getElementById('usersIndex'));
}
