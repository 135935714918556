import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select';
import { API, Helpers, Router, Translator } from '../Helpers';
import ErrorBoundary from "../../withErrorBoundary/withErrorBoundary";
import {InputHidden} from "../Core";

/**
 * Typography Manager Component
 */
export default class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fonts: [], // Fetched Google Fonts for Select dropdown
            typography: {
                body_font_family: this.findSiteSetting('body_font_family') || '',
                h1_font_family: this.findSiteSetting('h1_font_family') || '',
                h2_font_family: this.findSiteSetting('h2_font_family') || '',
                h3_font_family: this.findSiteSetting('h3_font_family') || '',
                h4_font_family: this.findSiteSetting('h4_font_family') || '',
                h5_font_family: this.findSiteSetting('h5_font_family') || '',
                h6_font_family: this.findSiteSetting('h6_font_family') || '',
            },
            loading: true,
            error: null,
            loadedFonts: new Set(), // Keep track of loaded fonts
            fontLoading: null, // Track the font currently loading
        };

        this.findSiteSetting = this.findSiteSetting.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    /**
     * Fetch Google Fonts
     */
    async componentDidMount() {
        try {
            const API_KEY = process.env.MIX_GOOGLE_FONT_API_KEY;
            const response = await fetch(
                `https://www.googleapis.com/webfonts/v1/webfonts?key=${API_KEY}`
            );

            if (!response.ok) {
                throw new Error('Failed to fetch Google Fonts');
            }

            const data = await response.json();
            const fonts = data.items.map((font) => ({
                value: font.family,
                label: font.family,
            }));

            this.setState({ fonts, loading: false });
        } catch (error) {
            this.setState({ error: error.message, loading: false });
        }
    }

    /**
     * Dynamically Load Font
     */
    async loadFont(fontFamily) {
        if (!fontFamily || this.state.loadedFonts.has(fontFamily)) return Promise.resolve();

        return new Promise((resolve) => {
            const fontUrl = `https://fonts.googleapis.com/css2?family=${fontFamily.replace(
                / /g,
                '+'
            )}:wght@400&display=swap`;

            if (!document.querySelector(`link[href="${fontUrl}"]`)) {
                const link = document.createElement('link');
                link.href = fontUrl;
                link.rel = 'stylesheet';

                link.onload = () => {
                    document.fonts.ready
                        .then(() => {
                            this.setState((prevState) => ({
                                loadedFonts: new Set(prevState.loadedFonts).add(fontFamily),
                            }));
                            resolve();
                        })
                        .catch(() => {
                            console.warn(`Failed to verify font load: ${fontFamily}`);
                            resolve();
                        });
                };

                document.head.appendChild(link);
            } else {
                document.fonts.ready.then(resolve);
            }
        });
    }

    /**
     * Handle Dropdown Changes
     */
    async handleChange(tag, selectedOption) {
        const fontFamily = selectedOption?.value || '';

        // Show loading indicator
        this.setState({ fontLoading: tag });

        // Load the font before applying it
        await this.loadFont(fontFamily);

        // Apply the font after it's loaded
        this.setState((prevState) => ({
            fontLoading: null,
            typography: {
                ...prevState.typography,
                [`${tag}_font_family`]: fontFamily,
            },
        }));
    }

    findSiteSetting(key)
    {
        let setting = _.find(this.props.data.active_site.settings, (setting) => { return setting.key == key; });

        if (!_.isEmpty(setting))
        {
            return setting.value;
        }
    }

    /**
     * Render Font Selector with react-select
     */
    renderFontSelect(tag) {
        const { fonts, typography, fontLoading } = this.state;

        const selectedValue = fonts.find(
            (option) => option.value === typography[`${tag}_font_family`]
        );

        return (
            <div className="form-group mb-3" key={tag}>
                <label className="font-weight-bold">
                    {tag.toUpperCase()} {fontLoading === tag && ' (Loading...)'}
                </label>
                <Select
                    name={tag + "_font_family"}
                    value={selectedValue || null}
                    onChange={(selectedOption) => this.handleChange(tag, selectedOption)}
                    options={fonts}
                    placeholder="Search and select a font"
                    isClearable
                    isSearchable
                />
            </div>
        );
    }

    /**
     * Render Live Preview Panel
     */
    renderPreviewPanel() {
        const { typography } = this.state;

        return (
            <div className="preview-panel p-4 border rounded shadow-sm bg-light">
                <h6 className="text-muted mb-3">Live Preview</h6>
                <h1 style={{ fontFamily: typography.h1_font_family }}>Heading One</h1>
                <h2 style={{ fontFamily: typography.h2_font_family }}>Heading Two</h2>
                <h3 style={{ fontFamily: typography.h3_font_family }}>Heading Three</h3>
                <h4 style={{ fontFamily: typography.h4_font_family }}>Heading Four</h4>
                <h5 style={{ fontFamily: typography.h5_font_family }}>Heading Five</h5>
                <h6 style={{ fontFamily: typography.h6_font_family }}>Heading Six</h6>
                <p style={{ fontFamily: typography.body_font_family }}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque tellus velit,
                    feugiat a nisi vitae, malesuada lacinia lacus. Fusce eleifend ultrices nulla.
                </p>
            </div>
        );
    }

    render() {
        const { active_site, admin_site, csrf_token, env, redirect_to, request, routes } = this.props.data;

        const router = Router(routes);
        const { loading, error } = this.state;

        if (loading) {
            return (
                <div className="text-center my-5">
                    <div className="spinner-border text-primary"></div>
                    <p>Loading fonts...</p>
                </div>
            );
        }

        if (error) {
            return (
                <div className="alert alert-danger text-center">
                    Error: {error}
                </div>
            );
        }

        return (
            <div className="container-fluid mb-4">
                <h2 className="mb-4">Typography Settings</h2>
                <form action={router.url('settings.update', { domain: admin_site.domain })}
                      method="POST">
                    <InputHidden name="_token" defaultValue={csrf_token} />
                    <InputHidden name="site_id" defaultValue={active_site.site.id} />
                    <InputHidden name="redirect_to" defaultValue={redirect_to} />

                    <div className="row">
                        {/* Font Selectors */}
                        <div className="col-md-6">
                            <div className="card p-4 border shadow-sm">
                                <h4 className="mb-3">Font Settings</h4>
                                {['body', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'].map((tag) => this.renderFontSelect(tag))}
                                <div className="text-right mt-3">
                                    <button type="submit" className="btn btn-sm btn-primary">
                                        Save Changes
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* Live Preview */}
                        <div className="col-md-6">{this.renderPreviewPanel()}</div>
                    </div>
                </form>
            </div>
        );
    }
}

/**
 * Mount Component
 */
if (document.getElementById('typographyEditor')) {
    const element = document.getElementById('typographyEditor');
    const data = Object.assign({}, element.dataset);

    ReactDOM.render(<ErrorBoundary><App data={JSON.parse(data.data)} /></ErrorBoundary>, element);
}
