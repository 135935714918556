import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class TextArea extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return (
            <div>
                {
                    this.props.label &&
                    <label htmlFor={this.props.name}>{this.props.label}</label>
                }

                <textarea
                    className="form-control"
                    {...this.props}
                    name={this.props.name}
                    defaultValue={this.props.defaultValue}
                    type="text"
                    
                    rows={this.props.rows}
                    onChange={this.props.onChange ? this.onChange : undefined}
                    required={this.props.required ? this.props.required : false}
                />
            </div>
        );
    }
}
