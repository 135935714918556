import { Router } from '../Helpers';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
// import FroalaEditorComponent from 'react-froala-wysiwyg';

import ErrorBoundary from '../../withErrorBoundary/withErrorBoundary';
import { InputHidden, InputText, Loader, TextArea } from "../Core";
// import EditorComponent from '../Core/FroalaEditor';


class PagesEdit extends Component {
    constructor(props) {
        super(props);

        let language_codes = this.props.data.languages

        if (!Array.isArray(this.props.data.languages)) {
            language_codes = Object.values(this.props.data?.languages);
        }

        this.state = {
            errors: {},
            search_string: null,
            form_id: "pagesEditForm_" + uniqid(),
            language_codes: language_codes,
            language_code: 'en-us' //this.props.data?.language_code
        };

        this.selectLanguage = this.selectLanguage.bind(this);
    }

    selectLanguage(code) {
        this.setState({
            language_code: code
        })
    }

    render() {

        const { admin_site, routes, domain, csrf_token, url, active_site, page, homepage } = this.props.data;
        const { translations, language_codes, language_code } = this.state;

        const router = Router(routes);

        return (
            <div className="container-fluid">
                <div className="row mb-3 align-items-center">
                    <div className="col-12 col-lg-8">
                        <h2>Edit Page <a href={router.url('pages.create', { domain: domain })} className="btn btn-primary">Add New</a></h2>
                    </div>

                    <div className="col-12 col-lg-4 justify-content-end">
                        <select
                            name="language_code"
                            className="custom-select form-control"
                            value={language_code}
                            onChange={(e) => this.selectLanguage(e.target.value)}
                        >
                            <option value="">Select a language</option>
                            {language_codes.map((language) => (
                                <option key={language?.id} value={language?.code}>
                                    {language?.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        {
                            page.page.slug === homepage ?
                                <React.Fragment>
                                    <span className="font-weight-bold">Permalink:</span> <a target="_blank" href={router.url('customer.home', { 'domain': active_site.site.domain })}>{router.url('customer.home', { 'domain': active_site.site.domain })}</a>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <span className="font-weight-bold">Permalink:</span> <a target="_blank" href={router.url('customer.pages.show', { 'domain': active_site.site.domain, 'page': page.page.slug })}>{router.url('customer.pages.show', { 'domain': active_site.site.domain, 'page': page.page.slug })}</a>
                                </React.Fragment>
                        }
                    </div>
                </div>

                <form
                    id={this.state.form_id}
                    method='POST'
                    action={router.url('pages.update', { page: page.page.id, domain: domain })}>
                    <InputHidden name="method" defaultValue={'PUT'} />
                    <InputHidden name="_token" defaultValue={csrf_token} />
                    <InputHidden id="site" name="site" defaultValue={active_site.site.id} />

                    <div className="row">
                        <div className="col-9">
                            <div className="row mb-3">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor='title'>Enter title here</label>
                                        <InputText id={'title'} name={'title'} defaultValue={page?.page?.title || null} />
                                    </div>
                                </div>
                            </div>

                            {
                                language_codes.map((p, index) => {

                                    try {

                                        const language_data = language_codes?.filter(x => x.code === p?.code)[0];
                                        const page_language_data = page?.translations?.filter(x => x.locale === p.code)[0];

                                        return (
                                            <div key={index} className={`card border mb-3 ${p.code !== language_code ? 'd-none' : ''}`}>
                                                <div className="card-body p-4">
                                                    <h3 className="font-weight-bold">{language_data?.name}</h3>
                                                    <hr className="mb-4" />
                                                    <div className="row mb-3">
                                                        <div className="col-12">
                                                            <div className="form-group">
                                                                <label htmlFor='content'>Content</label>
                                                                <TextArea
                                                                    id={'content'}
                                                                    name={`translations[${p?.code}][content]`}
                                                                    rows={3}
                                                                    defaultValue={page_language_data?.content}
                                                                    className="fr-editor form-control" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-12">
                                                            <div className="form-group">
                                                                <label htmlFor='seo_title'>SEO title</label>
                                                                <InputText
                                                                    id={'seo_title'}
                                                                    name={`translations[${p?.code}][seo_title]`}
                                                                    defaultValue={page_language_data?.seo_title} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor='meta_description'>Meta Description</label>
                                                                <TextArea
                                                                    id={'meta_description'}
                                                                    name={`translations[${p?.code}][meta_description]`}
                                                                    rows={3}
                                                                    defaultValue={page_language_data?.meta_description} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )

                                    } catch (e) {

                                    }
                                })
                            }

                            <div className="row mb-3">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header black white-text">
                                            Tag Manager
                                        </div>
                                        <div className="card-body">
                                            <div>
                                                <label htmlFor="pixel_tags">Add pixel tags here</label>
                                                <TextArea
                                                    id={'pixel_tags'}
                                                    name={`pixel_tags`}
                                                    rows={3}
                                                    defaultValue={page.page?.pixel_tags || null} />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className="col-3">
                            <div className="card">
                                <div className="card-header black white-text">
                                    Publish
                                </div>
                                <div className="card-body">
                                    <div className="form-group row">
                                        <div className="col-12">
                                            <label>Status</label>
                                            <select
                                                className="custom-select form-control"
                                                defaultValue={page.page.status}
                                                name="status"
                                                id="status">
                                                <option value="draft">Draft</option>
                                                <option value="published">Published</option>
                                            </select>
                                        </div>
                                    </div>

                                    <button type="submit" className="btn btn-primary btn-block">Publish</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>



            </div>
        )
    }
}

export default PagesEdit;

if (document.getElementById("pagesEdit")) {
    const element = document.getElementById("pagesEdit");
    const data = Object.assign({}, element.dataset);

    ReactDOM.render(<ErrorBoundary><PagesEdit data={JSON.parse(data.data)} /></ErrorBoundary>, document.getElementById("pagesEdit"));
}