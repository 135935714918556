import React, { Component } from "react";
import { API, Helpers, Router } from '../Helpers';
import { InputHidden, InputText, TextArea } from '../Core';
import SortableTree from "react-sortable-tree";
import Vapor from 'laravel-vapor';

class MenuEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu_items: this.defaultMenuItems(),
            // menu_items: [{ "id": 1, "url": "/home", "title": "Home", "type": "page", "expanded": true, "isExpanded": false, "children": [{ "id": 2, "url": "/car-hire-orlando-florida", "title": "Car Hire Orlando Florida", "type": "page", "expanded": true, "isExpanded": false, "children": [] }] }, { "id": 2, "url": "/car-hire-orlando-florida", "title": "Car Hire Orlando Florida", "type": "page", "expanded": true, "isExpanded": false, "children": [] }],
            selected_pages: [],
        };

        this.addCustomLinkToMenu = this.addCustomLinkToMenu.bind(this);
        this.addPagesToMenu = this.addPagesToMenu.bind(this);
        this.clearSelectedPages = this.clearSelectedPages.bind(this);
        this.defaultMenuItems = this.defaultMenuItems.bind(this);
        this.findSiteSetting = this.findSiteSetting.bind(this);
        this.removeFromMenu = this.removeFromMenu.bind(this);
        this.selectPage = this.selectPage.bind(this);
        this.setHeaderLayout = this.setHeaderLayout.bind(this);
        this.setMenuItems = this.setMenuItems.bind(this);
        this.toggleSelectPage = this.toggleSelectPage.bind(this);
        this.unselectPage = this.unselectPage.bind(this);
        this.updateMenuItem = this.updateMenuItem.bind(this);
    }

    // Add a new menu item
    addMenuItem = () => {
        const { menu_items } = this.state;
        const newItem = {
            id: menu_items.length + 1,
            title: "New Menu Item",
            url: "/new",
            children: [],
        };
        this.setState({ menu_items: [...menuItems, newItem] });
    };

    // Save menu to the backend
    saveMenu = async () => {
        
    };

    toggleNodeExpansion = (treeIndex) => {
        const updatedMenuItems = [...menuItems];
        updatedMenuItems[treeIndex].isExpanded = !updatedMenuItems[treeIndex].isExpanded;
        setMenuItems(updatedMenuItems); // Re-render the tree with the updated state
    };

    // Handle menu change
    handleMenuChange = (newTreeData) => {
        this.setState({ menu_items: newTreeData });
    };

    // Update menu item properties
    updateMenuItem = (nodeId, key, value) => {
        const { menu_items } = this.state;
        const updateNode = (nodes) =>
            nodes.map((node) => {
                if (node.id === nodeId) {
                    return { ...node, [key]: value };
                } else if (node.children) {
                    return { ...node, children: updateNode(node.children) };
                }
                return node;
            });
        this.setState({ menu_items: updateNode(menu_items) });
    };

    toggleNodeExpansion = (treeIndex) => {
        const { menu_items } = this.state;

        const toggleNode = (nodes) =>
            nodes.map((node, index) => {
                if (index === treeIndex) {
                    return { ...node, expanded: !node.expanded };
                } else if (node.children) {
                    return { ...node, children: toggleNode(node.children) };
                }
                return node;
            });

        this.setState({ menu_items: toggleNode(menu_items) });
    };

    //EDIT

    addCustomLinkToMenu(event) {
        event.preventDefault();

        console.log("addCustomLinkToMenu");

        const form = event.target;
        const formData = new FormData(form);

        let menu_items = [...this.state.menu_items];
        let menu_item = Helpers.serializeFormData(formData);

        menu_items.push(_.merge(menu_item, { id: uniqid(), type: 'custom' }));

        this.setMenuItems(menu_items);
    }

    addPagesToMenu() {
        console.log("addPagesToMenu");

        const router = Router(this.props.data.routes);

        let menu_items = [...this.state.menu_items];

        //console.log('menu_items', menu_items);
        //console.log(_.uniqueId());

        _.forEach(this.state.selected_pages, (page) => {

            let path = '/' + page.page.slug;
            let homepage = _.find(this.props.data.active_site.settings.data, (setting) => { return setting.key == 'homepage'; });

            if (!_.isEmpty(homepage)) {
                if (homepage.value == page.page.slug) {
                    //path += page.slug;

                    path = "/";
                }
            }

            console.log('page', page);

            menu_items.push({
                id: page?.page?.id || uniqid(),
                url: path, //router.url('customer.pages.show', {domain: this.props.data.active_site.domain, page: page.slug}),
                title: page.page.title,
                type: 'page',
                expanded: true,
                isExpanded: false,
                children: []
            });
        });

        this.setMenuItems(menu_items, true);
    }

    clearSelectedPages() {
        this.setState({
            selected_pages: []
        });
    }

    defaultMenuItems() {
        let menu_items = _.find(this.props.data.active_site.settings.data, (_setting) => { return _setting.key == 'menu_items'; });

        console.log('menu_items', menu_items)

        if (!_.isEmpty(menu_items)) {
            return JSON.parse(menu_items.value);
        }

        return [];
    }

    findSiteSetting(key) {
        let setting = _.find(this.props.data.active_site.settings, (setting) => { return setting.key == key; });

        if (!_.isEmpty(setting)) {
            return setting.value;
        }
    }

    removeFromMenu(index) {
        console.log("removeFromMenu", index);

        let menu_items = [...this.state.menu_items];

        menu_items = _.filter(menu_items, (menu_item, _index) => { return _index !== index; });

        this.setMenuItems(menu_items);
    }

    selectPage(page) {
        let selected_pages = [...this.state.selected_pages];

        this.setState({
            selected_pages: selected_pages.concat([page])
        }, () => {
            console.log("this.state.selected_pages", this.state.selected_pages);
        });
    }

    setMenuItems(newState, clearSelectedPages) {
        // console.log(newState);

        this.setState({
            menu_items: newState
        }, () => {
            if (clearSelectedPages) {
                this.clearSelectedPages();
            }
        });
    }

    toggleSelectPage(page, event) {
        //console.log("toggleSelectPage", page, event.target.checked);

        if (event.target.checked) {
            this.selectPage(page);
        }
        else {
            this.unselectPage(page);
        }
    }

    unselectPage(page) {
        //console.log("unselectPage", page);

        let selected_pages = this.state.selected_pages.filter((_page) => {
            return _page.page.id !== page.page.id;
        });

        this.setState({
            selected_pages: selected_pages
        }, () => {
            console.log("this.state.selected_pages", this.state.selected_pages);
        });
    }

    setHeaderLayout(event) {
        console.log("setHeaderLayout");

        //console.log(index);
        //console.log(event.target);
        console.log(event.target.value);
        console.log(event.target.name);

        this.setState({
            header_layout: event.target.value
        });
    }

    updateMenuItem(index, event) {
        console.log("updateMenuItem");

        let menu_items = [...this.state.menu_items]
        let menu_item = _.nth(menu_items, index);

        if (event.target.type == 'checkbox') {
            menu_item[event.target.name] = event.target.checked;
        }
        else {
            menu_item[event.target.name] = event.target.value;
        }

        //console.log(index);
        //console.log(event.target);
        //console.log(event.target.value);
        //console.log(event.target.name);

        console.log(menu_item);

        menu_items.splice(index, 1, menu_item);

        this.setMenuItems(menu_items);
    }

    render() {
        const { active_site, admin_site, csrf_token, env, pages, redirect_to, routes } = this.props.data;
        const router = Router(routes);
        // const { menuItems } = this.state;

        const menuItems = this.state.menu_items;

        console.log('menu_items', this.state.menu_items)

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-4">
                        <div className="card border mb-3">
                            <div className="card-header">
                                <h4 className="mb-0">Pages</h4>
                            </div>
                            <div className="card-body overflow-y-scroll" style={{ maxHeight: '200px' }}>
                                <ul className="list-unstyled">
                                    {
                                        pages.data.map((page, key) => {

                                            return (
                                                <li key={key}>
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id={"page_" + key}
                                                            onChange={(event) => { this.toggleSelectPage(page, event) }}
                                                            checked={_.findIndex(this.state.selected_pages, (_page) => { return _page.page.id == page.page.id; }) > -1}
                                                        />
                                                        <label className="custom-control-label" htmlFor={"page_" + key}>{page.page.title}</label>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            <div className="card-footer border-top-0">
                                <div className="float-right">
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        disabled={_.isEmpty(this.state.selected_pages)}
                                        onClick={this.addPagesToMenu}>
                                        Add to menu
                                    </button>
                                </div>
                            </div>
                        </div>

                        <form onSubmit={this.addCustomLinkToMenu}>
                            <div className="card border">
                                <div className="card-header">
                                    <h4 className="mb-0">Custom Links</h4>
                                </div>
                                <div className="card-body overflow-y-scroll" style={{ maxHeight: '300px' }}>
                                    <div className="form-group row">
                                        <div className="col-12">
                                            <InputText name="url" label="URL" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-12">
                                            <InputText name="title" label="Title" />
                                        </div>
                                    </div>

                                    <div className="form-group form-row row">
                                        <div className="col-12">
                                            <InputText name="icon" label="Icon" />
                                        </div>
                                    </div>

                                    <div className="form-group form-row row">
                                        <div className="col-12">
                                            <InputText name="css_id" label="CSS ID (optional)" />
                                        </div>
                                    </div>

                                    <div className="form-group form-row row">
                                        <div className="col-12">
                                            <InputText name="css_class" label="CSS Classes (optional)" />
                                        </div>
                                    </div>

                                    <div className="form-group form-row row">
                                        <div className="col-12">
                                            <TextArea
                                                name="data_attributes"
                                                label="Data attributes (comma separated)"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-12">
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" name="open_in_new_tab" value="1" className="custom-control-input" id="openInNewTab" />
                                                <label className="custom-control-label" htmlFor="openInNewTab">Open in new tab</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer border-top-0">
                                    <div className="float-right">
                                        <button className="btn btn-primary" type="submit">
                                            Add to menu
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-8">
                        <div className="card border mb-5" style={{ minHeight: '100%' }}>
                            <div className="card-header">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h4 className="mb-0">Menu Structure</h4>
                                </div>
                            </div>
                            <div className="card-body">
                                {
                                    _.isEmpty(this.state.menu_items) ?
                                        <div className="mb-3">
                                            <div className="row mb-4">
                                                <div className="col-12">
                                                    <div>Add menu items from the column on the left.</div>
                                                </div>
                                            </div>
                                        </div> :
                                        <div className="mb-3">
                                            <div className="row mb-4">
                                                <div className="col-12">
                                                    <div>Drag each item into the order you prefer. Click the arrow to the right of each item to reveal additional configuration options.</div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12">
                                                    <div style={{ height: 550 }}>

                                                        {/* {JSON.stringify(menuItems)} */}
                                                        <SortableTree
                                                            key={uniqid()}
                                                            treeData={menuItems}
                                                            // rowHeight={500}
                                                            rowHeight={({ node }) => { console.log('node.isExpanded', node); return (node.isExpanded ? 450 : 62) }}
                                                            onChange={this.handleMenuChange}
                                                            // onChange={(newTreeData) => this.setMenuItems(newTreeData)}
                                                            generateNodeProps={({ node, treeIndex }) => ({
                                                                title: (
                                                                    <div>
                                                                        <div className="row">
                                                                            <div className="col-8">
                                                                                <div className="media">
                                                                                    {/* <div className="align-self-center mr-2">
                                                                                            <button
                                                                                                className="btn btn-flat"
                                                                                                onClick={() => this.removeFromMenu(treeIndex)}
                                                                                            >
                                                                                                <i className="fa fa-times"></i>
                                                                                            </button>
                                                                                        </div> */}
                                                                                    <div className="media-body align-self-center">
                                                                                        <div className="font-weight-bold">
                                                                                            {node.title}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-4 text-right p-0">
                                                                                <button
                                                                                    className="btn btn-flat"
                                                                                    onClick={() => {
                                                                                        // this.toggleNodeExpansion(treeIndex)
                                                                                        const updatedMenuItems = [...menuItems];
                                                                                        updatedMenuItems[treeIndex].isExpanded = !node.isExpanded;
                                                                                        this.handleMenuChange(updatedMenuItems);
                                                                                    }}
                                                                                >
                                                                                    <span className="text-muted">page</span> <i className={`fa fa-angle-${!node.isExpanded ? "down" : "up"}`}></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        {node.isExpanded && (

                                                                            <div className="row">
                                                                                <div className="col-12">
                                                                                    <div className="card border rounded-0 mt-2">
                                                                                        <div className="card-body">
                                                                                            <div className="row">
                                                                                                <div className="col-6">
                                                                                                    <div className="form-group row">
                                                                                                        <div className="col-12">
                                                                                                            <InputText
                                                                                                                name={`menu_items[${treeIndex}][url]`}
                                                                                                                label="URL"
                                                                                                                defaultValue={node.url}
                                                                                                                onChange={(event) => this.updateMenuItem(treeIndex, event)}
                                                                                                                disabled={node.type === "page"}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="form-group row">
                                                                                                        <div className="col-12">
                                                                                                            <InputText
                                                                                                                name={`menu_items[${treeIndex}][title]`}
                                                                                                                label="Navigation title"
                                                                                                                defaultValue={node.title}
                                                                                                                onChange={(event) => this.updateMenuItem(treeIndex, event)}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="form-group row">
                                                                                                        <div className="col-12">
                                                                                                            <InputText
                                                                                                                name={`menu_items[${treeIndex}][icon]`}
                                                                                                                label="Icon"
                                                                                                                defaultValue={node.icon}
                                                                                                                onChange={(event) => this.updateMenuItem(treeIndex, event)}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>
                                                                                                <div className="col-6">
                                                                                                    <div className="form-group row">
                                                                                                        <div className="col-12">
                                                                                                            <InputText
                                                                                                                name={`menu_items[${treeIndex}][css_id]`}
                                                                                                                label="CSS ID (optional)"
                                                                                                                defaultValue={node.css_id}
                                                                                                                onChange={(event) => this.updateMenuItem(treeIndex, event)}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="form-group row">
                                                                                                        <div className="col-12">
                                                                                                            <InputText
                                                                                                                name={`menu_items[${treeIndex}][css_class]`}
                                                                                                                label="CSS Classes (optional)"
                                                                                                                defaultValue={node.css_class}
                                                                                                                onChange={(event) => this.updateMenuItem(treeIndex, event)}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="form-group row">
                                                                                                        <div className="col-12">
                                                                                                            <TextArea
                                                                                                                name={`menu_items[${treeIndex}][data_attributes]`}
                                                                                                                label="Data attributes (comma separated)"
                                                                                                                defaultValue={node.data_attributes}
                                                                                                                onChange={(event) => this.updateMenuItem(treeIndex, event)}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="form-group row mb-0">
                                                                                                        <div className="col-12">
                                                                                                            <div className="custom-control custom-checkbox">
                                                                                                                <input
                                                                                                                    type="checkbox"
                                                                                                                    name={`menu_items[${treeIndex}][open_in_new_tab]`}
                                                                                                                    value="1"
                                                                                                                    className="custom-control-input"
                                                                                                                    id={`openInNewTab_${treeIndex}`}
                                                                                                                    defaultChecked={node.open_in_new_tab}
                                                                                                                    onChange={(event) => this.updateMenuItem(treeIndex, event)}
                                                                                                                />
                                                                                                                <label
                                                                                                                    className="custom-control-label"
                                                                                                                    htmlFor={`openInNewTab_${treeIndex}`}
                                                                                                                >
                                                                                                                    Open in new tab
                                                                                                                </label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>


                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        )}
                                                                    </div>
                                                                ),
                                                            })}
                                                        />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                            <div className="card-footer d-flex justify-content-end">
                                <form action={router.url('settings.store')} method="POST">
                                    <InputHidden name="_token" defaultValue={csrf_token} />
                                    <InputHidden name="site_id" defaultValue={active_site.id} />
                                    <InputHidden name="redirect_to" defaultValue={redirect_to} />

                                    <div className="d-none">
                                        <textarea name="menu_items" value={JSON.stringify(this.state.menu_items)} readOnly={true} />
                                    </div>
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        disabled={_.isEmpty(this.state.menu_items) || true}>
                                        Save changes
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default MenuEditor;
