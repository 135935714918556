import { API, Helpers, Router } from '../Helpers';
// import { Checkbox, Radio, RadioGroup } from 'react-icheck';
import { InputHidden, InputText, TextArea } from '../Core';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { ReactSortable } from "react-sortablejs";
import Vapor from 'laravel-vapor';
import MenuEditor from './MenuEditor';

export default class Edit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            header_layout: this.findSiteSetting('header_layout'),
            menu_items: this.defaultMenuItems(),
            selected_pages: [],
        };

        this.addCustomLinkToMenu = this.addCustomLinkToMenu.bind(this);
        this.addPagesToMenu = this.addPagesToMenu.bind(this);
        this.clearSelectedPages = this.clearSelectedPages.bind(this);
        this.defaultMenuItems = this.defaultMenuItems.bind(this);
        this.findSiteSetting = this.findSiteSetting.bind(this);
        this.removeFromMenu = this.removeFromMenu.bind(this);
        this.selectPage = this.selectPage.bind(this);
        this.setHeaderLayout = this.setHeaderLayout.bind(this);
        this.setMenuItems = this.setMenuItems.bind(this);
        this.toggleSelectPage = this.toggleSelectPage.bind(this);
        this.unselectPage = this.unselectPage.bind(this);
        this.updateMenuItem = this.updateMenuItem.bind(this);
    }

    addCustomLinkToMenu(event)
    {
        event.preventDefault();

        console.log("addCustomLinkToMenu");

        const form = event.target;
        const formData = new FormData(form);

        let menu_items = [...this.state.menu_items];
        let menu_item = Helpers.serializeFormData(formData);

        menu_items.push(_.merge(menu_item, {id: uniqid(), type: 'custom'}));

        this.setMenuItems(menu_items);
    }

    addPagesToMenu()
    {
        console.log("addPagesToMenu");

        const router = Router(this.props.data.routes);

        let menu_items = [...this.state.menu_items];

        //console.log('menu_items', menu_items);
        //console.log(_.uniqueId());

        _.forEach(this.state.selected_pages, (page) => {

            let path = '/' + page.page.slug;
            let homepage = _.find(this.props.data.active_site.settings.data, (setting) => { return setting.key == 'homepage'; });

            if (!_.isEmpty(homepage))
            {
                if (homepage.value == page.page.slug)
                {
                    //path += page.slug;

                    path = "/";
                }
            }

            //console.log(path);

            menu_items.push({
                id: uniqid(),
                url: path, //router.url('customer.pages.show', {domain: this.props.data.active_site.domain, page: page.slug}),
                navigation_label: page.page.title,
                type: 'page',
                page_id: page.page.id
            });
        });

        this.setMenuItems(menu_items, true);
    }

    clearSelectedPages()
    {
        this.setState({
            selected_pages: []
        });
    }

    defaultMenuItems()
    {
        let menu_items = _.find(this.props.data.active_site.settings, (_setting) => { return _setting.key == 'menu_items'; });

        //console.log('menu_items', menu_items)

        if (!_.isEmpty(menu_items))
        {
            return JSON.parse(menu_items.value);
        }

        return [];
    }

    findSiteSetting(key)
    {
        let setting = _.find(this.props.data.active_site.settings, (setting) => { return setting.key == key; });

        if (!_.isEmpty(setting))
        {
            return setting.value;
        }
    }

    removeFromMenu(index)
    {
        console.log("removeFromMenu", index);

        let menu_items = [...this.state.menu_items];

        menu_items = _.filter(menu_items, (menu_item, _index) => { return _index !== index; });

        this.setMenuItems(menu_items);
    }

    selectPage(page)
    {
        let selected_pages = [...this.state.selected_pages];

        this.setState({
            selected_pages: selected_pages.concat([page])
        }, () =>
        {
            console.log("this.state.selected_pages", this.state.selected_pages);
        });
    }

    setMenuItems(newState, clearSelectedPages)
    {
        // console.log(newState);

        this.setState({
            menu_items: newState
        }, () =>
        {
            if (clearSelectedPages)
            {
                this.clearSelectedPages();
            }
        });
    }

    toggleSelectPage(page, event)
    {
        //console.log("toggleSelectPage", page, event.target.checked);

        if (event.target.checked)
        {
            this.selectPage(page);
        }
        else
        {
            this.unselectPage(page);
        }
    }

    unselectPage(page)
    {
        //console.log("unselectPage", page);

        let selected_pages = this.state.selected_pages.filter((_page) =>
        {
            return _page.page.id !== page.page.id;
        });

        this.setState({
            selected_pages: selected_pages
        }, () =>
        {
            console.log("this.state.selected_pages", this.state.selected_pages);
        });
    }

    setHeaderLayout(event)
    {
        console.log("setHeaderLayout");

        //console.log(index);
        //console.log(event.target);
        console.log(event.target.value);
        console.log(event.target.name);

        this.setState({
            header_layout: event.target.value
        });
    }

    updateMenuItem(index, event)
    {
        console.log("updateMenuItem");

        let menu_items = [...this.state.menu_items]
        let menu_item = _.nth(menu_items, index);

        if (event.target.type == 'checkbox')
        {
            menu_item[event.target.name] = event.target.checked;
        }
        else
        {
            menu_item[event.target.name] = event.target.value;
        }

        //console.log(index);
        //console.log(event.target);
        //console.log(event.target.value);
        //console.log(event.target.name);

        console.log(menu_item);

        menu_items.splice(index, 1, menu_item);

        this.setMenuItems(menu_items);
    }

    render() {

        const { active_site, admin_site, csrf_token, env, pages, redirect_to, routes } = this.props.data;
        const router = Router(routes);

        return (
            <div className="container-fluid mb-3">
                <div className="row">
                    <div className="col-12">
                        <h2>Header</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <form action={router.url('settings.update', {domain: admin_site.domain})} method="POST">
                            <InputHidden name="_token" defaultValue={csrf_token} />
                            <InputHidden name="site_id" defaultValue={active_site.site.id} />
                            <InputHidden name="redirect_to" defaultValue={redirect_to} />
                            <input type="hidden" name="header_layout" value={this.state.header_layout} readOnly={true} />

                            <div className="card border mb-5">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <h4>Choose a layout to use for the header</h4>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-6">
                                            <div className="card border h-100">
                                                <div className="card-body">
                                                    <div className="custom-control custom-radio mb-3">
                                                        <input
                                                            name="header_layout"
                                                            type="radio"
                                                            value="1"
                                                            className="custom-control-input"
                                                            id="headerLayout1"
                                                            onChange={(event) => {this.setHeaderLayout(event)}}
                                                            checked={this.state.header_layout == 1}
                                                        />
                                                        <label className="custom-control-label" htmlFor="headerLayout1">Layout 1</label>
                                                    </div>

                                                    <div>
                                                        <img className="img-fluid z-depth-1" src={ Vapor.asset('images/layout1.jpg') } />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="card border h-100">
                                                <div className="card-body">
                                                    <div className="custom-control custom-radio mb-3">
                                                        <input
                                                            name="header_layout"
                                                            type="radio"
                                                            value="2"
                                                            className="custom-control-input"
                                                            id="headerLayout2"
                                                            onChange={(event) => {this.setHeaderLayout(event)}}
                                                            checked={this.state.header_layout == 2}
                                                        />
                                                        <label className="custom-control-label" htmlFor="headerLayout2">Layout 2</label>
                                                    </div>

                                                    <div>
                                                        <img className="img-fluid z-depth-1" src={ Vapor.asset('images/layout2.jpg') } />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row form-group">
                                        <div className="col-12">
                                            <InputHidden name="show_topbar_ribbon" value={0} />
                                            <label className="form-check-label mb-2" htmlFor="show_topbar">
                                                Show the topbar ribbon
                                            </label>
                                            <div className="switch">
                                                <label>
                                                    No
                                                    <input
                                                        name="show_topbar_ribbon"
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={1}
                                                        id="show_topbar_ribbon"
                                                        defaultChecked={this.findSiteSetting('show_topbar_ribbon') == 1 ? 1 : 0}
                                                    />
                                                    <span className="lever"></span>
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12">
                                            <InputHidden name="keep_topbar_fixed" value={0} />
                                            <label className="form-check-label mb-2" htmlFor="keep_topbar_fixed">
                                                Keep the topbar fixed while scrolling
                                            </label>
                                            <div className="switch">
                                                <label>
                                                    No
                                                    <input
                                                        name="keep_topbar_fixed"
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={1}
                                                        id="topbar_fixed_top"
                                                        defaultChecked={this.findSiteSetting('keep_topbar_fixed') == 1 ? 1 : 0}
                                                    />
                                                    <span className="lever"></span>
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <button
                                        className="btn btn-primary"
                                        type="submit">
                                        Save changes
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <MenuEditor data={this.props.data} />

                {
                    false &&
                <div className="row">
                    <div className="col-4">
                        <div className="card border mb-3">
                            <div className="card-header">
                                <h4 className="mb-0">Pages</h4>
                            </div>
                            <div className="card-body overflow-y-scroll" style={{ maxHeight: '200px' }}>
                                <ul className="list-unstyled">
                                    {
                                        pages.data.map((page, key) => {

                                            return (
                                                <li key={key}>
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id={"page_" + key}
                                                            onChange={(event) => {this.toggleSelectPage(page, event)}}
                                                            checked={_.findIndex(this.state.selected_pages, (_page) => { return _page.page.id == page.page.id; }) > -1}
                                                        />
                                                        <label className="custom-control-label" htmlFor={"page_" + key}>{page.page.title}</label>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            <div className="card-footer border-top-0">
                                <div className="float-right">
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        disabled={_.isEmpty(this.state.selected_pages)}
                                        onClick={this.addPagesToMenu}>
                                        Add to menu
                                    </button>
                                </div>
                            </div>
                        </div>

                        <form onSubmit={this.addCustomLinkToMenu}>
                            <div className="card border">
                                <div className="card-header">
                                    <h4 className="mb-0">Custom Links</h4>
                                </div>
                                <div className="card-body overflow-y-scroll" style={{ maxHeight: '300px' }}>
                                    <div className="form-group row">
                                        <div className="col-12">
                                            <InputText name="url" label="URL" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-12">
                                            <InputText name="navigation_label" label="Navigation label" />
                                        </div>
                                    </div>

                                    <div className="form-group form-row row">
                                        <div className="col-12">
                                            <InputText name="icon" label="Icon" />
                                        </div>
                                    </div>

                                    <div className="form-group form-row row">
                                        <div className="col-12">
                                            <InputText name="css_id" label="CSS ID (optional)" />
                                        </div>
                                    </div>

                                    <div className="form-group form-row row">
                                        <div className="col-12">
                                            <InputText name="css_class" label="CSS Classes (optional)" />
                                        </div>
                                    </div>

                                    <div className="form-group form-row row">
                                        <div className="col-12">
                                            <TextArea
                                                name="data_attributes"
                                                label="Data attributes (comma separated)"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-12">
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" name="open_in_new_tab" value="1" className="custom-control-input" id="openInNewTab" />
                                                <label className="custom-control-label" htmlFor="openInNewTab">Open in new tab</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer border-top-0">
                                    <div className="float-right">
                                        <button className="btn btn-primary"  type="submit">
                                            Add to menu
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-8">
                        <div className="card border mb-5" style={{minHeight: '75%'}}>
                            <div className="card-header">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h4 className="mb-0">Menu Structure</h4>
                                </div>
                            </div>
                            <div className="card-body">
                                {
                                    _.isEmpty(this.state.menu_items) ?
                                        <div className="mb-3">
                                            <div className="row mb-4">
                                                <div className="col-12">
                                                    <div>Add menu items from the column on the left.</div>
                                                </div>
                                            </div>
                                        </div> :
                                        <div className="mb-3">
                                            <div className="row mb-4">
                                                <div className="col-12">
                                                    <div>Drag each item into the order you prefer. Click the arrow to the right of each item to reveal additional configuration options.</div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12">
                                                    <ReactSortable
                                                        list={this.state.menu_items}
                                                        setList={(newState) => this.setMenuItems(newState)}
                                                    >
                                                        {
                                                            this.state.menu_items.map((menu_item, key) => {

                                                                return (
                                                                    <div key={menu_item.id} className="mb-2">
                                                                        <div
                                                                            className="list-group-item px-2 d-flex justify-content-between rounded-0">
                                                                            <div>
                                                                                <div className="media">
                                                                                    <div className="align-self-center mr-2">
                                                                                        <button
                                                                                            className="btn btn-flat"
                                                                                            onClick={() => this.removeFromMenu(key)}
                                                                                        >
                                                                                            <i className="fa fa-times"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                    <div className="media-body align-self-center">
                                                                                        <div className="font-weight-bold">{menu_item.navigation_label}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <a className="btn btn-flat" data-toggle="collapse" href={"#collapseMenuItem_" + key} role="button">
                                                                                    <i className="fa fa-angle-down"></i>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                        <div className="collapse" id={"collapseMenuItem_" + key}>
                                                                            <div className="card border rounded-0">
                                                                                <div className="card-body">
                                                                                    <div className="form-group row">
                                                                                        <div className="col-12">
                                                                                            <InputText
                                                                                                name={"menu_items[" + key + "][url]"}
                                                                                                label="URL"
                                                                                                defaultValue={menu_item.url}
                                                                                                onChange={(event) => {this.updateMenuItem(key, event)}}
                                                                                                disabled={menu_item.type == 'page'}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="form-group row">
                                                                                        <div className="col-12">
                                                                                            <InputText
                                                                                                name={"menu_items[" + key + "][navigation_label]"}
                                                                                                label="Navigation label"
                                                                                                defaultValue={menu_item.navigation_label}
                                                                                                onChange={(event) => {this.updateMenuItem(key, event)}}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="form-group form-row row">
                                                                                        <div className="col-12">
                                                                                            <InputText
                                                                                                name={"menu_items[" + key + "][icon]"}
                                                                                                label="Icon"
                                                                                                defaultValue={menu_item.icon}
                                                                                                onChange={(event) => {this.updateMenuItem(key, event)}}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="form-group form-row row">
                                                                                        <div className="col-12">
                                                                                            <InputText
                                                                                                name={"menu_items[" + key + "][css_id]"}
                                                                                                label="CSS ID (optional)"
                                                                                                defaultValue={menu_item.css_id}
                                                                                                onChange={(event) => {this.updateMenuItem(key, event)}}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="form-group form-row row">
                                                                                        <div className="col-12">
                                                                                            <InputText
                                                                                                name={"menu_items[" + key + "][css_class]"}
                                                                                                label="CSS Classes (optional)"
                                                                                                defaultValue={menu_item.css_class}
                                                                                                onChange={(event) => {this.updateMenuItem(key, event)}}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="form-group form-row row">
                                                                                        <div className="col-12">
                                                                                            <TextArea
                                                                                                name={"menu_items[" + key + "][data_attributes]"}
                                                                                                label="Data attributes (comma separated)"
                                                                                                defaultValue={menu_item.data_attributes}
                                                                                                onChange={(event) => {this.updateMenuItem(key, event)}}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="form-group row mb-0">
                                                                                        <div className="col-12">
                                                                                            <div className="custom-control custom-checkbox">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    name={"menu_items[" + key + "][open_in_new_tab]"}
                                                                                                    value="1"
                                                                                                    className="custom-control-input"
                                                                                                    id={"openInNewTab_" + key}
                                                                                                    defaultChecked={menu_item.open_in_new_tab}
                                                                                                    onChange={(event) => {this.updateMenuItem(key, event)}}
                                                                                                />
                                                                                                <label className="custom-control-label" htmlFor={"openInNewTab_" + key}>Open in new tab</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </ReactSortable>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                            <div className="card-footer d-flex justify-content-end">
                                <form action={router.url('settings.update', {domain: admin_site.domain})} method="POST">
                                    <InputHidden name="_token" defaultValue={csrf_token} />
                                    <InputHidden name="site_id" defaultValue={active_site.site.id} />
                                    <InputHidden name="redirect_to" defaultValue={redirect_to} />

                                    <div className="d-none">
                                        <textarea name="menu_items" value={JSON.stringify(this.state.menu_items)} readOnly={true} />
                                    </div>
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        disabled={_.isEmpty(this.state.menu_items)}>
                                        Save changes
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
    }
            </div>
        );
    }
}

if (document.getElementById('headerEdit')) {

    const element = document.getElementById('headerEdit');
    const data = Object.assign({}, element.dataset);

    //console.log(data.data);

    ReactDOM.render(<Edit data={JSON.parse(data.data)} />, document.getElementById('headerEdit'));
}
