import { Router } from '../Helpers';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ErrorBoundary from '../../withErrorBoundary/withErrorBoundary';
import { InputHidden, Loader } from "../Core";

class PagesCreate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            search_string: null,
            form_id: "pagesCreateForm_" + uniqid()
        };
    }

     render() {
        return (
            <div>

            </div>
        )
     }
}

export default PagesCreate;

if (document.getElementById("pagesCreate")) {
    const element = document.getElementById("pagesCreate");
    const data = Object.assign({}, element.dataset);

    ReactDOM.render(<ErrorBoundary><PagesCreate data={JSON.parse(data.data)} /></ErrorBoundary>, document.getElementById("pagesCreate"));
}