import { API, Helpers, Router, Translator } from '../Helpers';
import {
    InputHidden,
    InputText
} from '../Core';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class CancelBookingModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cancellation_fee: this.props.booking.cancellation_fee.amount,
            future_credit: false,
            refund_amount: this.calculateRefundAmount(this.props.booking.vehicle.package.payments.payNow.total.payment.amount, this.props.booking.cancellation_fee.amount),
            showForm: false
        };

        this.calculateRefundAmount = this.calculateRefundAmount.bind(this);
        this.cancelBooking = this.cancelBooking.bind(this);
        this.changeCancellationFee = this.changeCancellationFee.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.hide = this.hide.bind(this);
        this.onCancelBooking = this.onCancelBooking.bind(this);
        this.toggleFutureCredit = this.toggleFutureCredit.bind(this);
        this.updateRefundAmount = this.updateRefundAmount.bind(this);
    }

    componentDidMount() {
        $('#cancelBookingModal').on('show.bs.modal', (event) => {
            this.setState({
                showForm: true
            }, () => {
                $('#cancelBookingForm').validate({
                    //debug: true,
                    errorClass: "is-invalid",
                    validClass: "is-valid",
                    errorPlacement: () => { /*just nothing, empty*/ }
                });
            });
        });

        $('#cancelBookingModal').on('hidden.bs.modal', (event) => {
            this.setState({
                showForm: false
            });
        });
    }

    calculateRefundAmount(payment_amount, cancellation_fee) {
        let refund_amount = payment_amount - cancellation_fee;

        if (refund_amount > 0) {
            return refund_amount;
        }

        return 0;
    }

    cancelBooking(payload) {
        // console.log('cancelBooking', payload);

        let onResponse = {
            success: (response) => {
                //console.log('cancelBooking Response', response);

                this.setState({
                    show_loader: false
                }, () => {
                    this.onCancelBooking();
                });
            },
            error: error => {
                this.setState({
                    show_loader: false,
                    errors: error,
                    booking: {}
                });
            }
        }

        this.setState({
            show_loader: true
        }, () => {
            API.delete(onResponse, payload, this.props.router.url('bookings.destroy', { booking: this.props.booking.reservNum, domain: this.props.admin_site.domain }));
        });
    }

    changeCancellationFee(event) {
        //console.log('changeCancellationFee', event.target.value);

        this.setState({
            cancellation_fee: event.target.value
        }, () => {
            let refund_amount = this.calculateRefundAmount(this.props.booking.vehicle.package.payments.payNow.total.payment.amount, this.state.cancellation_fee);

            this.updateRefundAmount(refund_amount);
        });
    }

    hide() {
        $('#cancelBookingModal').modal('hide');
    }

    onCancelBooking() {
        toastr.success('Booking cancelled');

        this.hide();

        let payload = {
            booking: this.props.booking.reservNum,
            email: this.props.booking.customer.email
        };

        this.props.fetchBooking(payload);
    }

    handleSubmit(event) {
        event.preventDefault();

        if ($('#cancelBookingForm').valid()) {
            let formData = Helpers.serializeForm(event.target);

            let payload = _.omit(formData, ['cancellationFee']);

            if (!_.isEmpty(formData.cancellationFee)) {
                if (!_.isEmpty(formData.cancellationFee.amount)) {
                    payload.cancellationFee = {
                        amount: formData.cancellationFee.amount,
                        currency: formData.cancellationFee.currency
                    }
                }
            }

            // console.log(payload);

            this.cancelBooking(payload);
        }
    }

    toggleFutureCredit() {
        this.setState({
            future_credit: !this.state.future_credit
        }, () => {
            let refund_amount = this.state.future_credit ? 0 : this.props.booking.vehicle.package.payments.payNow.total.payment.amount;

            this.updateRefundAmount(refund_amount);
        });
    }

    updateRefundAmount(value) {
        //console.log('updateRefundAmount', value);

        this.setState({
            refund_amount: value
        });
    }

    render() {
        const id = uniqid();
        const { admin_site, booking, cancellation_reasons, csrf_token, env, user } = this.props;

        return (
            <div id="cancelBookingModal" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <form id="cancelBookingForm" onSubmit={this.handleSubmit}>
                            {
                                !_.isEmpty(booking) &&
                                this.state.showForm &&
                                <div>
                                    <InputHidden name="_token" defaultValue={csrf_token} />
                                    <InputHidden name="_method" defaultValue="DELETE" />

                                    <InputHidden id="accessToken" name="accessToken" defaultValue={booking.accessToken} />
                                    <InputHidden id="userId" name="userId" defaultValue={user.user.id} />

                                    <div className="modal-header p-4">
                                        <h2 className="font-weight-bold mb-0">
                                            <ul className="list-inline mb-0">
                                                <li className="list-inline-item">Cancel booking #{booking.reservNum}</li>
                                            </ul>
                                        </h2>
                                        <button type="button" className="close" data-dismiss="modal">
                                            <span aria-hidden="true"><i className="fa fa-times"></i></span>
                                        </button>
                                    </div>
                                    <div className="modal-body p-4">
                                        <div className="row">
                                            <div className="col-12">
                                                <ul className="list-unstyled mb-4">
                                                    <li className="mb-2"><span className="font-weight-bold">Total profit:</span> {booking.vehicle.package.payments.payNow.total.payment.currency} {Helpers.formatCurrency(this.props.totalProfit(), booking.vehicle.package.payments.payNow.total.payment.currency, env.APP_LOCALE)}</li>
                                                    <li className="mb-2"><span className="font-weight-bold">Total paid:</span> {booking.vehicle.package.payments.payNow.total.payment.currency} {Helpers.formatCurrency(booking.vehicle.package.payments.payNow.total.payment.amount, booking.vehicle.package.payments.payNow.total.payment.currency, env.APP_LOCALE)}</li>
                                                    <li className="mb-2"><span className="font-weight-bold">Amount to refund:</span> {booking.vehicle.package.payments.payNow.total.payment.currency} {Helpers.formatCurrency(this.state.refund_amount, booking.vehicle.package.payments.payNow.total.payment.currency, env.APP_LOCALE)}</li>
                                                </ul>

                                                <div className="form-group row">
                                                    <div className="col-6">
                                                        <label>Reason for cancellation</label>
                                                        <select id="cancellationReason" name="reason" className="browser-default custom-select rounded-0" required>
                                                            <option value="">choose an option</option>

                                                            {
                                                                cancellation_reasons.data.map((cancellation_reason, key) => {
                                                                    return (
                                                                        <option key={key} value={cancellation_reason.name}>{cancellation_reason.name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <div className="col-12">
                                                        <div className="card border rounded-0">
                                                            <div className="card-body p-4">
                                                                <div>
                                                                    <div className="custom-control custom-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="custom-control-input"
                                                                            id="futureCredit"
                                                                            onChange={this.toggleFutureCredit}
                                                                            checked={this.state.future_credit}
                                                                            name="futureCredit"
                                                                            value="1"
                                                                        />
                                                                        <label className="custom-control-label" htmlFor="futureCredit">Cancel with future credit</label>
                                                                    </div>
                                                                </div>

                                                                {
                                                                    !this.state.future_credit &&
                                                                    <div className="row mt-4">
                                                                        <div className="col-8">
                                                                            <label>Cancellation fee</label>
                                                                            <div className="input-group">
                                                                                <input
                                                                                    id="cancellationFeeAmount"
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                    placeholder="Amount"
                                                                                    name="cancellationFee[amount]"
                                                                                    value={this.state.cancellation_fee}
                                                                                    onChange={this.changeCancellationFee}
                                                                                />
                                                                                <div className="input-group-append">
                                                                                    <span className="input-group-text">USD</span>
                                                                                </div>
                                                                            </div>

                                                                            <InputHidden id="cancellationFeeCurrency" name="cancellationFee[currency]" defaultValue={booking.vehicle.package.payments.payNow.total.payment.currency} />
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group row mb-0">
                                                    <div className="col-12">
                                                        <div className="card border rounded-0">
                                                            <div className="card-body p-4">
                                                                <div className="custom-control custom-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="custom-control-input"
                                                                        id="confirmCancellation"
                                                                        name="confirmCancellation"
                                                                        defaultValue="1"
                                                                        required
                                                                    />
                                                                    <label className="custom-control-label" htmlFor="confirmCancellation">I confirm cancellation of this booking</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer d-flex justify-content-between align-items-center">
                                        <div>
                                            <button type="button" className="btn btn-link" data-dismiss="modal">Close</button>
                                        </div>
                                        <div>
                                            <ul className="list-inline mb-0">
                                                <li className="list-inline-item">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary"
                                                    >
                                                        <ul className="list-inline mb-0 d-flex align-items-center justify-content-center">
                                                            {
                                                                this.state.show_loader &&
                                                                <li className="list-inline-item mr-2">
                                                                    <div className="spinner-border spinner-border-sm d-block" role="status"></div>
                                                                </li>
                                                            }

                                                            <li className="list-inline-item">
                                                                Cancel booking
                                                            </li>
                                                        </ul>
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
